import React from 'react';
import { graphql } from 'gatsby';
import Text from '@components/Text';
import ContentfulText from '@components/ContentfulText';

export default function BasicPage({ data }) {
  return (
    <>
      <Text>
        <ContentfulText text={data?.contentfulPage?.pageContent} />
      </Text>
    </>
  );
}

export const query = graphql`
  query BasicPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      featuredImage {
        gatsbyImageData(width: 640, aspectRatio: 1)
      }
      pageTitle
      pageContent {
        raw
      }
    }
  }
`;
